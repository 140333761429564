import Article from './helpers/Article.js';
import preprintfig from '../images/train-data.png'

const published_articles = [
  {
    title: 'Segment anything in medical images',
    authors: ["Jun Ma", "Yuting He", "Feifei Li", "Lin Han", "Chenyu You", "Bo Wang"],
    journal: 'Nature Communication',
    date: 'January 22, 2024',
    link: 'https://www.nature.com/articles/s41467-024-44824-z'
  },
  {
    title: 'The multimodality cell segmentation challenge: toward universal solutions',
    authors: [
      "Jun Ma",
      "Ronald Xie",
      "Shamini Ayyadhury",
      "Cheng Ge",
      "Anubha Gupta",
      "Ritu Gupta",
      "Song Gu",
      "Yao Zhang",
      "Gihun Lee",
      "Joonkee Kim",
      "Wei Lou",
      "Haofeng Li",
      "Eric Upschulte",
      "Timo Dickscheid",
      "José Guilherme de Almeida",
      "Yixin Wang",
      "Lin Han",
      "Xin Yang",
      "Marco Labagnara",
      "Vojislav Gligorovski",
      "Maxime Scheder",
      "Sahand Jamal Rahi",
      "Carly Kempster",
      "Alice Pollitt",
      "Leon Espinosa",
      "Tâm Mignot",
      "Jan Moritz Middeke",
      "Jan-Niklas Eckardt",
      "Wangkai Li",
      "Zhaoyang Li",
      "Xiaochen Cai",
      "Bizhe Bai",
      "Noah F. Greenwald",
      "David Van Valen",
      "Erin Weisbart",
      "Beth A. Cimini",
      "Trevor Cheung",
      "Oscar Brück",
      "Gary D. Bader",
      "Bo Wang"
    ],
    journal: 'Nature Methods',
    date: 'March 26, 2024',
    link: 'https://www.nature.com/articles/s41592-024-02233-6'
  },
  {
    title: 'Towards foundation models of biological image segmentation',
    authors: ['Jun Ma', 'Bo Wang'],
    journal: 'Nature Methods',
    date: 'July 11, 2023',
    link: 'https://www.nature.com/articles/s41592-023-01885-0'
  }
];

const preprints = [
  {
    title: 'MedSAM2: Segment Anything in 3D Medical Images and Videos',
    authors: [
      "Jun Ma",
      "Zongxin Yang",
      "Sumin Kim",
      "Bihui Chen",
      "Mohammed Baharoon",
      "Adibvafa Fallahpour",
      "Reza Asakereh",
      "Hongwei Lyu",
      "Bo Wang"
    ],
    image: preprintfig,
    status: 'Submitted',
    link: 'https://arxiv.org/abs/2504.03600'
  }
];


export default function Resources() {
    return (
      <div>
        <div className="element-containter">
          <h1>Check out our latest release!</h1>
          <div className="article-container">
            {preprints.map((article, index) => (
              <Article key={index} article={article} />
            ))}
          </div>
        </div>
        <div className="element-containter">
          <h1>Published articles</h1>
          <div className="article-container">
            {published_articles.map((article, index) => (
              <Article key={index} article={article} />
            ))}
          </div>
        </div>
      </div>
      
    );
  }