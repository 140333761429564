import { Link } from 'react-router-dom';
import { useState } from 'react'
import logoimg from './images/M31LogoGrey.png'
import { RxHamburgerMenu } from "react-icons/rx";


// import './App.css';

export default function Navbar() {
  const [showNavbar, setShowNavbar] = useState(false)

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar)
  }

  return (
    <nav className="nav-bar-wrapper">
      <div className="logo">
        <Link to="/">
          <img src={logoimg} alt="M31 Logo" className="logo-img" />
        </Link>
      </div>
      <div className="menu-icon" onClick={handleShowNavbar}>
          <RxHamburgerMenu />
      </div>
      <div className={`nav-elements  ${showNavbar && 'active'}`}>
        <ul className="nav-links">
          <li className="nav-item"><Link to="/Products">PRODUCTS</Link></li>
          <li className="nav-item"><Link to="/Services">SERVICES</Link></li>
          <li className="nav-item"><Link to="/Resources">RESOURCES</Link></li>
          <li className="nav-item"><Link to="/Contact">CONTACT</Link></li>
          <li className="nav-item"><Link to="/About">ABOUT</Link></li>
        </ul>
      </div>
      
    </nav>
  );
}
